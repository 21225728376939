import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./EVs.scss";

import EVCatalog from "../../client_customizations/components/EVCatalog/EVCatalog";
import MatchScoreOptions from "./../../components/MatchScoreOptions/MatchScoreOptions";
import EvSortControls from "./../../components/EvSortControls/EvSortControls";

import EVFilterControls from "../../client_customizations/components/EVFilterControls/EVFilterControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";

import AdBlock from "../../client_customizations/components/Advertisements/AdBlock";

import {FormattedMessage, useIntl} from 'react-intl';

const EVs = ({ electricVehicles, ip, uuid }) => {
  const intl = useIntl() 

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_VEHICLES_TITLE;
  });

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <MatchScoreOptions />
      <br />
      <EVFilterControls vehicles={electricVehicles}/>
    </>
  );


  return (
    <section id="EVs">
      <div className="container">
        <div className="row mb-3 title-area">
          <div className="col-sm-12 text-center">
            <h2>
              <FormattedMessage 
                id="electricVehicles"
                defaultMessage="Electric Vehicles"
                description="Electric Vehicles"
              />
            </h2>
            <p className="lead">
              <FormattedMessage 
                id="evs.welcomeSub"
                defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle."
                description="Description of Colton Recharged"
                values= {{
                  lineBreak: <br />
                }}
              />
              </p>
              <a 
                href="https://www.energysage.com/electric-vehicles/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about EVs from EnergySage
              </a>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 d-block d-lg-none text-center">
            <ModalComponent
              buttonText={intl.formatMessage ? intl.formatMessage({ id: "evs.buttonMatchScoreAndFilters", defaultMessage: "Match Score and Filters"}) : "Match Score and Filters"}
              titleText={intl.formatMessage ? intl.formatMessage({ id: "evs.matchScoreAndFilters", defaultMessage: "Refine Match Score and Filters"}) : "Refine Match Score and Filters"}
            >
              {renderOptions}
            </ModalComponent>
            <br />
          </div>
          <div className="col-xl-3 col-lg-4 d-none d-lg-block">
            {renderOptions}
            <AdBlock 
              type="small" 
              eventDetails={{
                category: "Polestar Ad", 
                action:"Clicked Polestar Ad", 
                label: "EV Catalog Small Ad"
              }}
            />
          </div>
          <div
            id="electric-vehicles-catalog"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: "absolute", top: "-9999px" }}
          >
            <span>Results have been updated as of {time}.</span>
          </div>
          <div
            className="col-xl-9 col-lg-8 col-sm-12"
            style={{ paddingLeft: 10 }}
          >
            <div className="row">
              <div className="col">
                <div className="pull-right">
                  <EvSortControls />
                </div>
              </div>
            </div>
            <EVCatalog
              vehicles={electricVehicles}
              hasEvDetails
              hasIncentivesAndMatchScore
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EVs;

EVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
