import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
// import SmoothScroll from "../../../../utils/Helpers/SmoothScroll"
import { FormattedMessage } from 'react-intl';
import twitterIcon from "../../../assets/images/icons/twitter-icon.svg";
import facebookIcon from "../../../assets/images/icons/facebook-icon.svg";
import linkedinIcon from "../../../assets/images/icons/linkedin-icon.svg";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

// import { MAP_TABS } from '../../../../constants/mapTabs';

const Footer = ({ changeLanguage }) => {

  // const onChangeLanguage = e => {
  //   changeLanguage(e.target.value)
  //   SmoothScroll("root")
  // }

  return (
    <div className="Footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="social-links">
              <a href="https://twitter.com/energysage" target="_blank" rel="noopener noreferrer">
                <img src={twitterIcon} alt="Twitter" />
              </a>
              <a href="https://www.linkedin.com/company/energysage" target="_blank" rel="noopener noreferrer">
                <img src={linkedinIcon} alt="LinkedIn" />
              </a>
              <a href="https://www.facebook.com/EnergySage" target="_blank" rel="noopener noreferrer">
                <img src={facebookIcon} alt="Facebook" />
              </a>
            </div>
            <div className="sitemap">
              <ul>
                <li>
                  {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
                    <Link to="/vehicles">
                      <FormattedMessage
                        id="electricVehicles"
                        defaultMessage="Electric Vehicles"
                        description="Electric Vehicles Footer"
                        values={{
                          electricVehicles: process.env.REACT_APP_PAGES_VEHICLES_TITLE
                        }}
                      />
                    </Link>
                  </>) : (null)}
                </li>
                <li>
                  {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (<>
                    <Link to="/compare-vehicles">
                      <FormattedMessage
                        id="compareVehicles"
                        defaultMessage="Compare Vehicles"
                        description="Compare Vehicles Footer"
                        values={{
                          compareVehicles: process.env.REACT_APP_PAGES_COMPARE_TITLE
                        }}
                      />
                    </Link>
                  </>) : (null)}
                </li>
                <li>
                  {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (<>
                    <Link to="/incentives">
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives Footer"
                        values={{
                          incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE
                        }}
                      />
                    </Link>
                    <br />
                  </>) : (null)}
                </li>
              </ul>
            </div>
            <div className="copyright">
              <p style={{marginLeft:"-400px"}}><span>&copy;</span> {(new Date().getFullYear().toString())} {process.env.REACT_APP_FULL_COMPANY_NAME}. All Rights Reserved.</p>
              {/* <p>
                <span> &copy;
                  {(new Date().getFullYear().toString()) + " "} J.D. Power ZappyRide (c). {" "}
                  <a rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices">Legal notices.</a>
                </span>
              </p> */}
              <span className="disclaimer_container">
                  {/* <DisclaimerComponent clientName="zappynobackground"/> */}

                  <DisclaimerComponent
                    clientName="zappyride"
                    textStyle={{
                      fontFamily: "Open Sans, Helvetica, sans-serif",
                      fontSize: "13px",
                      color: "#7E7E7E"
                    }}
                    imageStyle={{ marginLeft: "300px", marginTop:"-35px" }}
                    wrapperStyle={{
                      padding: "0px",
                    }}
                  />
                </span>
            </div>
            <div className="back-to-site">
              <a href="https://www.energysage.com/">Back to EnergySage</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;