import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./EVs.scss";
import { Link } from "react-router-dom";
import EVFilterControls from "../../client_customizations/components/EVFilterControls/EVFilterControls";
import RangeVsCostGraph from "../../components/CostsCharts/CostVsRangeGraph"
import BlueTiles from "../../client_customizations/assets/images/blue_tiles.svg"
import WhiteTiles from "../../client_customizations/assets/images/white_tiles.svg"
import ActiveGraphIcon from "../../client_customizations/assets/images/Active_Graph_Icon.svg"

const EVsGraph = ({ electricVehicles, ip, uuid }) => {

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_COMPARISON_GRAPH_TITLE;
  });

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <EVFilterControls vehicles={electricVehicles}/>
      <br />
      <p className="legal-disclaimer">
        Vehicles displayed may not reflect actual availability. {process.env.REACT_APP_FULL_COMPANY_NAME} does 
        not endorse or recommend any specific vehicle or car manufacturer.
      </p>
    </>
  );

  return (
    <section className="container">
      <div className="row mb-3">
        <div className="col-sm-12">
          <h1>Electric Vehicles</h1>
          <p className="lead">
            Compare electric cars by EV range or price. Hover on the EV for more details.
            </p>
        </div>
        <div className="d-block d-md-none mobileText">
          <div className="input-well">
            <p>
              To see the Range vs. Cost Graph, please view this page on a desktop.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center evsToggle d-none d-lg-block">
        <Link
          to={`/vehicles`}
        >
          <button
            type="button"
            className="toggle-btn"
          >
            <img 
              className="evs-toggle-image evs-toggle-image-passive" 
              alt="Blue Tile Icon"
              src={BlueTiles}>
            </img>
            <img 
              className="evs-toggle-image evs-toggle-image-hover"
              alt="White Tile Icon" 
              src={WhiteTiles}>
            </img>
              Vehicle Tiles
          </button>
        </Link>

        <Link
          to={`/comparison-graph`}
        >
          <button
            type="button"
            className="toggle-btn-active"
          >
            <img 
              className="evs-toggle-image"
              alt="Graph Icon" 
              src={ActiveGraphIcon}>
            </img>
              Range vs. Cost
          </button>
        </Link>
      </div>
      <div className="row">
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="electric-vehicles-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>Results have been updated as of {time}.</span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12 d-none d-lg-block"
          style={{ paddingLeft: 10 }}
        >
          <div className="row">
            <div className="col">
              <div className="pull-right">
              </div>
            </div>
          </div>
          <RangeVsCostGraph electricVehicles={electricVehicles}/>
        </div>
      </div>
    </section>
  );
};

export default EVsGraph;

EVsGraph.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
