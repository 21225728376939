import React, {useState, useEffect } from "react";
import PropTypes from "prop-types";
// import PageLayout from "../../client_customizations/components/PageLayout/PageLayout"
import { Link } from "react-router-dom";

import ComparedVehicle from "../../client_customizations/components/CompareVehicles/ComparedVehicle"
import CostOfOwnership from "../../components/CostOfOwnership/CostOfOwnership"

import AdBlock from "../../client_customizations/components/Advertisements/AdBlock";

import SpeedOfCharge from "../../components/SpeedOfCharge/SpeedOfCharge"
import ElectricRange from "../../components/ElectricRange/ElectricRange"

import "./CompareVehicles.scss"

import ComparedCarDetails from "./ComparedCarDetails";
import ComparedVehicleToggle from "./ComparedVehicleToggle";

import {FormattedMessage, useIntl} from 'react-intl';


 const CompareVehicles = ({ ip, uuid, props, electricVehicles, gasolineVehicles }) => {

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_COMPARE_TITLE;
  });

  const [comparedVehicle1, setComparedVehicle1] = useState(null)
  const [comparedVehicle2, setComparedVehicle2] = useState(null)
  const [comparedVehicle3, setComparedVehicle3] = useState(null)

  const [selectedVehicleNumber, setSelectedVehicleNumber] = useState(0);

  const cars = [comparedVehicle1, comparedVehicle2, comparedVehicle3].filter(car => car !== null)

  const intl = useIntl()

  if (!electricVehicles || !gasolineVehicles) {
    return null;
  }

  gasolineVehicles = gasolineVehicles.filter(vehicle => {
    return vehicle.images.length !== 0
  })

  const title = {
    costAnalysis: intl.formatMessage({ id: "graph.title.costAnalysis", defaultMessage: "Cost Analysis"}),
    speedOfCharge: intl.formatMessage({ id: "graph.title.speedOfCharge", defaultMessage: "Speed of Charge"}),
    electricRange: intl.formatMessage({ id: "graph.title.electricRange", defaultMessage: "Electric Range"})
  }

  let vehicle1ContainerClasses = ["col-sm-4", "d-sm-block"];
  if (selectedVehicleNumber !== 0) vehicle1ContainerClasses.push("d-none");

  let vehicle2ContainerClasses = ["col-sm-4", "d-sm-block"];
  if (selectedVehicleNumber !== 1) vehicle2ContainerClasses.push("d-none");

  let vehicle3ContainerClasses = ["col-sm-4", "d-sm-block"];
  if (selectedVehicleNumber !== 2) vehicle3ContainerClasses.push("d-none");
  
  return (
      <section id="CompareVehicles">
        <div className="gray-block"></div>
        <div className="title-area">
            <div className="container">
                <h2>
                  <FormattedMessage 
                    id="compareVehicles"
                    defaultMessage="Compare Vehicles"
                    description="Compare Vehicles Title"
                  />
                </h2>
                <p>Pick at least 2 electric vehicles to begin the comparison.</p>
                <Link to="/vehicles">
                  <FormattedMessage 
                    id="compareVehicles.subTitle"
                    defaultMessage="See All Vehicles"
                    description="Compare Vehicles Sub Title"
                  />
                </Link>
              </div>
            <div className="row">
              <div className="col-sm-12">
              <ComparedVehicleToggle
                      selectedVehicleNumber={selectedVehicleNumber}
                      onChangeVehicleNumber={number =>
                        setSelectedVehicleNumber(number)
                      }
                      comparedVehicles={[comparedVehicle1, comparedVehicle2, comparedVehicle3]}
                    />
              </div>
            </div>
        </div>

        
        <>
          <section id="compared-vehicle-container">
            <div className="container">
              <div className="row">
                <div className={vehicle1ContainerClasses.join(" ")}>
                  <ComparedVehicle
                    vehicle={comparedVehicle1}
                    setVehicle={setComparedVehicle1}
                    electricVehicles={electricVehicles}
                    gasolineVehicles={gasolineVehicles}
                  />
                  {comparedVehicle1 && 
                    <ComparedCarDetails vehicle={comparedVehicle1}/>
                  }
                </div>

                <div className={vehicle2ContainerClasses.join(" ")}>
                  <ComparedVehicle
                    vehicle={comparedVehicle2}
                    setVehicle={setComparedVehicle2}
                    electricVehicles={electricVehicles}
                    gasolineVehicles={gasolineVehicles}
                  />
                  {comparedVehicle2 &&
                    <ComparedCarDetails vehicle={comparedVehicle2}/>
                  }
                </div>

                <div className={vehicle3ContainerClasses.join(" ")}>
                  <ComparedVehicle
                    vehicle={comparedVehicle3}
                    setVehicle={setComparedVehicle3}
                    electricVehicles={electricVehicles}
                    gasolineVehicles={gasolineVehicles}
                  />
                  {comparedVehicle3 && 
                    <ComparedCarDetails vehicle={comparedVehicle3}/>
                  }
                </div>
              </div>
            </div>
          </section>

          {cars.length > 1 ? 
          <>
            <section id="compare-cost-of-ownership">
            <div className="container">
                <CostOfOwnership
                  cars={cars}
                  title={title.costAnalysis}
                  comparison={"comparison"}
                />
            </div>
          </section>

          <section id="compare-speed-of-charge">
            <div className="container">
                <SpeedOfCharge
                  cars={cars}
                  title={title.speedOfCharge}
                  comparison={"comparison"}
                />

            </div>
          </section>

          <section id="compare-electric-range">
            <div className="container">
                <ElectricRange
                  cars={cars}
                  title={title.electricRange}
                  comparison={"comparison"}
                />
            </div>
          </section>
          <AdBlock 
              type="long" 
              eventDetails={{
                category: "Polestar Ad", 
                action:"Clicked Polestar Ad", 
                label: "Compare Vehicles Ad"
              }}
            />          
          </>
          :
            <div className="text-center single-vehicle">
              <h3>
                <FormattedMessage 
                  id="compareVehicles.pickTwo"
                  defaultMessage="Pick at least 2 vehicles to start the comparison"
                  description="Pick at least 2 vehicles to start the comparison"
                />
              </h3>
              <h5>
                <FormattedMessage 
                  id="compareVehicles.disclaimer"
                  defaultMessage="Not all make/models are avaiable."
                  description="Not all make/models are avaiable."
                />
              </h5>
            </div>
          }
        </>

      </section>
  );
};
  
export default CompareVehicles;

CompareVehicles.propTypes = {
  electricVehicles: PropTypes.array
};
