import React from "react";
import PropTypes from "prop-types";
import LinkCard from   "../../../../components/LinkCard/LinkCard";

const HomepageBigPromise = ({
  homePageBannerImage
}) => {
  return (
    <section className="container p-0">
      <div className="big-promise" style={{ position: "relative" }}>
        <div className="gradient-top-left" />
        <div
          className="banner"
          style={{
            backgroundImage: "url(" + homePageBannerImage + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            WebkitBackgroundSize: "1200px auto",
            MozBackgroundSize: "1200px auto",
            OBackgroundSize: "1200px auto",
            backgroundSize: "1200px auto",
            // padding: 0,
            textAlign: "center",
            height: "475px",
            boxSizing: "border-box"
          }} >
            <h1>EnergySage’s Guide to Electric Vehicles</h1>
            <p>Learn about the environmental and financial benefits of driving electric!</p>
          </div>
      </div>

      <div id="link-card-buttons">
        <div className="row">
          <div className="col">
            <div className="link-card-container">
              <LinkCard type="BROWSE_VEHICLES" />
              <LinkCard type="DISCOVER_INCENTIVES" />
              <LinkCard type="COMPARE_VEHICLES" />
              {/* <LinkCard type="LOCATE_CHARGING_STATIONS" /> */}
              {/* <LinkCard type="EV_FACTS" /> */}
              {/* <LinkCard type="FIND_DEALERS" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string
}