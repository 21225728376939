import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap";

import logo from "../../../assets/images/logo.svg"
import "./Header.scss";
import {FormattedMessage} from 'react-intl';
// import { MAP_TABS } from '../../../../constants/mapTabs';

const Header = ({ page }) => {
  const [collapse, setCollapse] = useState(false);
  // const isHomepage = window.location.pathname === "/" || window.location.pathname === null;

  return (
    <div className="Header">
      <div className="container" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
            <a
              href="/"
              // target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid"
              />
            </a>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              {/* { process.env.REACT_APP_PAGES_HOME_ENABLED  ? (
                <NavItem>
                  <Link to="/" className={isHomepage ? "active" : ""}>
                    <span>Home</span>
                  </Link>
                </NavItem>
              ):(null)} */}
              { process.env.REACT_APP_PAGES_VEHICLES_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="header.electricVehicles"
                        defaultMessage="Electric Vehicles"
                        description="Electric Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_COMPARE_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "compare" ? "Active Page" : null}
                    to="/compare-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="header.compareVehicles"
                        defaultMessage="Compare Vehicles"
                        description="Compare Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "home-chargers" ? "Active Page" : null}
                    to="/home-chargers"
                  >
                    <span>
                      <FormattedMessage
                        id="homeChargers"
                        defaultMessage="Home Chargers"
                        description="Home Chargers Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ): (null)}
              { process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/used-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="usedElectricVehicles"
                        defaultMessage="Used Vehicles"
                        description="Used Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_INCENTIVES_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/incentives"
                  >
                    <span>
                      <FormattedMessage
                        id="header.incentives"
                        defaultMessage="Incentives"
                        description="Incentives Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}

              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  className={page === "map" ? "active" : ""}
                >
                  <span>Maps</span>
                </DropdownToggle>
                <DropdownMenu right>
                  {MAP_TABS.filter(tab => tab.isEnabled).map(tab => {
                    return (
                      <DropdownItem
                        key={`nav-item-${tab.id}`}
                        title={page === "map" ? "Active Page" : null}
                        tag={NavLink}
                        to={tab.url}
                      >
                        {tab.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown> */}
        
              { process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK ? "Active Page" : null}
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                  >
                    <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>FAQ</span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_EVENTS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "events" ? "Active Page" : null}
                    to="/events"
                  >
                    <span>Events</span>
                  </NavLink>
                </NavItem>
              ):(null)}
                <NavItem>
                  <a
                    href="https://www.energysage.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Back to EnergySage</span>
                  </a>
                </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};
