import React from "react";
import PropTypes from "prop-types";
import "./AdBlock.scss";

import desktopImage from "../../assets/images/advertisements/ad-desktop.jpg";
import mobileImage from "../../assets/images/advertisements/ad-mobile.jpg";
import GaTracker from "../../../utils/GaTracker/GaTracker"

const AdBlock = ({
       type,
       eventDetails
}) => {

       let image = desktopImage;
       if(type === "small") {
              image = mobileImage;
       }
       
       let handleAdClick = () => {
              GaTracker.trackEvent({
                     category: eventDetails?.category,
                     action: eventDetails?.action,
                     label: eventDetails?.label,
              });
       }

       return (
              <section onClick={handleAdClick} className={"placement-block container " + type}>
                     <a
                            href="https://www.polestar.com/us/polestar-2?utm_medium=pp&utm_source=dp&utm_campaign=Polestar_Polestar-Brand_Q2-Campaign_US_NA&utm_content=pcid_5693_70502_183076_511483_p_us_1local_pp_dp"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <img src={image} alt="advertisement" />
                     </a>
              </section>
       );
};

export default AdBlock;

AdBlock.propTypes = {
   type: PropTypes.string
}