import React from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import {FormattedMessage} from 'react-intl';
import { FormatAsDollars } from "./../../../utils/Helpers/Format";

const IncentiveCard = props => {
  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility

  if (eligibility === "ineligible") {
    return null
  }

  let name = incentive.grantor + " " + incentive.name || "";
  let description = incentive.description || "";

  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : "";

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;

  const incentiveType = (type) => {
    if(type === "Rebate") {
      return (
        <FormattedMessage
          id="incentives.type.rebate"
          defaultMessage="Vehicle Rebates"
          description="Vehicle Rebates"
        />
      )
    } else if(type === "Financing") {
      return (
        <FormattedMessage
          id="incentives.type.financing"
          defaultMessage="Vehicle Financing"
          description="Vehicle Financing"
        />
      )
    } else if(type === "Tax Credit") {
      return (
        <FormattedMessage
          id="incentives.type.taxCredit"
          defaultMessage="Tax Credit"
          description="Tax Credit"
        />
      )
    } else if(type === "HOV Lane") {
      return (
        <FormattedMessage
          id="incentives.type.hovLane"
          defaultMessage="HOV Lane"
          description="HOV Lane"
        />
      )
    } else {
      return type;
    }
  }
    
  let renderCard = incentive ? (
    <a
      className="evc-card IncentiveCard"
      href={incentive.details_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="IncentiveCardBody">
        <h2>
          {incentiveType(incentive.type)}
        </h2>
        <p className="h1 card-title">
          {amount ? FormatAsDollars(amount) : amountDescription}
        </p>
        <p className="h6">{name}</p>
      </div>
      <div className="pl-5 pr-5">
        <hr />
      </div>
      <div className="IncentiveCardBottom">{description}</div>
    </a>
  ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool
};
