// Source: https://taxfoundation.org/2021-sales-taxes/#Combined

const MUNICIPALITY_INFO = {
    "Alabama" : {salestax: "0.0922", insurance: "1358"},
    "Alaska" : {salestax: "0.0176", insurance: "1175"},
    "Arizona" : {salestax: "0.084", insurance: "1417"},
    "Arkansas" : {salestax: "0.0951", insurance: "1462"},
    "California" : {salestax: "0.0868", insurance: "1966"},
    "Colorado" : {salestax: "0.0772", insurance: "1574"},
    "Connecticut" : {salestax: "0.0635", insurance: "1580"},
    "DC" : {salestax: "0", insurance: "1881"},
    "Delaware" : {salestax: "0.06", insurance: "1443"},
    "Florida": { salestax: "0.0708", insurance: "2082"},
    "Georgia" : {salestax: "0.0732", insurance: "1705"},
    "Hawaii" : {salestax: "0.0444", insurance: "1254"},
    "Idaho" : {salestax: "0.0603", insurance: "985"},
    "Illinois" : {salestax: "0.0882", insurance: "1205"},
    "Indiana" : {salestax: "0.07", insurance: "1189"},
    "Iowa" : {salestax: "0.0694", insurance: "1032"},
    "Kansas" : {salestax: "0.0869", insurance: "1454"},
    "Kentucky" : {salestax: "0.06", insurance: "1321"},
    "Louisiana": { salestax: "0.0952", insurance: "2839"},
    "Maine" : {salestax: "0.055", insurance: "858"},
    "Maryland" : {salestax: "0.06", insurance: "1410"},
    "Massachusetts" : {salestax: "0.0625", insurance: "1314"},
    "Michigan": { salestax: "0.06", insurance: "2112"},
    "Minnesota" : {salestax: "0.0746", insurance: "1407"},
    "Mississippi" : {salestax: "0.0707", insurance: "1378"},
    "Missouri" : {salestax: "0.0825", insurance: "1966"},
    "Montana" : {salestax: "0", insurance: "1627"},
    "Nebraska" : {salestax: "0.0694", insurance: "1383"},
    "Nevada" : {salestax: "0.0823", insurance: "1380"},
    "New Hampshire" : {salestax: "0", insurance: "885"},
    "New Jersey" : {salestax: "0.066", insurance: "1319"},
    "New Mexico" : {salestax: "0.0783", insurance: "1391"},
    "New York" : {salestax: "0.0852", insurance: "1163"},
    "North Carolina" : {salestax: "0.0698", insurance: "1326"},
    "North Dakota" : {salestax: "0.0696", insurance: "1234"},
    "Ohio" : {salestax: "0.0723", insurance: "992"},
    "Oklahoma" : {salestax: "0.0895", insurance: "1393"},
    "Oregon" : {salestax: "0", insurance: "1229"},
    "Pennsylvania" : {salestax: "0.0634", insurance: "1028"},
    "Rhode Island" : {salestax: "0.07", insurance: "1801"},
    "South Carolina" : {salestax: "0.0746", insurance: "1409"},
    "South Dakota" : {salestax: "0.064", insurance: "1575"},
    "Tennessee" : {salestax: "0.0955", insurance: "1206"},
    "Texas" : {salestax: "0.0819", insurance: "1710"},
    "Utah" : {salestax: "0.0719", insurance: "1250"},
    "Vermont" : {salestax: "0.0624", insurance: "1151"},
    "Virginia" : {salestax: "0.0573", insurance: "1033"},
    "Washington" : {salestax: "0.0923", insurance: "1298"},
    "West Virginia" : {salestax: "0.065", insurance: "1299"},
    "Wisconsin" : {salestax: "0.0543", insurance: "938"},
    "Wyoming" : {salestax: "0.0533", insurance: "1768"},
};

export {
    MUNICIPALITY_INFO
};